/**
 * Solicitudes Pragma SA
 */

@import 'styles/mixins';

.timeline {
  display: flex;
  flex-direction: column;
  background-color: var(--color-primary);

  .show {
    min-height: 40px;
    max-height: 40px;
    border-radius: 0 0 8px 8px;
    font-size: 12px;
    font-weight: 100;

    &.visible {
      border-radius: 0;

      &:focus {
        box-shadow: none;
      }

      &:hover {
        box-shadow: none;
      }
    }
    
    .dark {
      display: block;
      margin-right: 12px;
    }

    .lighting {
      display: none;
      margin-right: 12px;
      border-radius: 50px;
    }

    .shadow {
      box-shadow: 0 0 2px var(--color-white);
      background: radial-gradient(circle, rgba(255 255 255 / 100%) 0%, rgba(255 255 255 / 29%) 100%);
      filter: blur(7px);
      border-radius: 50px;
      height: 20px;
      width: 20px;
      display: none;
      position: absolute;
      top: 10px;
    }

    &:hover{
      font-weight: 700;

      .lighting {
        display: block;
      }

      .shadow {
        display: block;
      }

      .dark { 
        display: none;
      }
    }
  }

  .items {
    @include scrollbar;
    display: flex;
    flex-wrap: nowrap;
    padding: 18px;
  }
}
