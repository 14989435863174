/**
 * Solicitudes Pragma SA
 */

@import "styles/mixins";

.review {
  @include dynamic-height(column, true);
  gap: 18px;
  padding: 4px;
  padding: 16px;

  .container-request{
    display: flex;
    align-items: center;
    gap: 18px;
  }

  .id-title {
    font-size: 16px;
    font-weight: 700;
    color: var(--color-white);
  }
  
  .container-request-id{
    background-color: var(--color-shadow-white-primary);
    border-radius: 50%;
    min-width: 64px;
    height: 64px;
    align-items: center;
    justify-content: center;
    display: flex;
  }

  .id-data {
    font-size: 21px;
    font-weight: 600;
  }

  h3 {
    color: var(--color-white);
    padding-bottom: 4px;
    font-size: 18px;
  }
}

.approve-msg {
  border-radius: 8px;
  border: 2px solid var(--color-success);
  box-shadow: 0 1px 0 0 var(--color-success)  ;
  color: var(--color-success);

  h4 {
    padding: 4px 8px;
    background-color: var(--color-success);
    color: var(--color-white);
  }

  p {
    font-size: 12px;
    font-weight: 500;
    color: var(--color-text);
    padding: 8px 12px;
  }
}

.actions {
  display: flex;
  justify-content: center;
  gap: 12px;
}

.discount {
  display: flex;
  flex-direction: column;
  gap: 12px;

  .checkbox {
    display: flex;
    gap: 12px;
  }
}
