/**
 * Solicitudes Pragma SA
 */

 .menu-approver {
    display: flex;
    align-items: center;
    gap: 12px;
  
    .info {
      display: flex;
      flex-direction: column;
    }
  }
  