.salute {
  display: flex;
  padding: 18px;
  gap: 12px;
  margin-bottom: 34px;

  border-radius: 24px;
  border: 1px solid var(--color-primary);
  box-shadow: 0 0 15px rgba(100 41 205 / 60%), inset 0 0 30px rgba(100 41 205 / 50%);
  background: rgba(100 41 205 / 10%);
  color: var(--color-text);

  @media (max-width: 540px) {
    flex-flow: column;
  }

  .left {
    display: flex;
    align-items: center;
    flex: 2;
    gap: 18px;

    img {
      border-radius: 50%;
      min-width: 64px;
      height: 64px;
    }

    .user {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      font-size: 18px;
      flex: 1;

      h2 {
        margin: 0 0 2px;
        font-size: 16px;
        font-weight: 400;
        color: var(--color-white);

        .name {
          display: inline;
        }

        .custom{
          font-weight: 700;
          font-size: 20px;
        }
      }
      
      .extra {
        color: var(--color-white);
        font-weight: 700;
      }
    }
  }

  .right {
    display: flex;
    align-items: center;
    border-left: 2px solid var(--color-white);
    padding-left: 50px;

    @media (max-width: 540px) {
      border: 0;
      flex-direction: column;
    }
  }
}
