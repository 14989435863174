$box-color: #fff;

.box {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-end;
    position: fixed;
    row-gap: 10px;
    z-index: 1000;
    bottom: 60px;
    right: 50px;

    &__info {
        position: relative;
        background-color: white;
        color: #2b194a;
        font-size: 12px;
        font-weight: bold;
        width: 240px;
        padding: 5px 10px;
        border-radius: 10px;
        display: none;
    }

    &__arrow {
        position: absolute;
        right: 20px;
        width:0;
        top: 38px;
        height:0;
        border-left:10px solid transparent;
        border-right:10px solid transparent;
        border-top:10px solid white;
        font-size:0;
        line-height:0;
    }
}

.hlp-buton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 55px;
    height: 55px;
    border-radius: 50%;
    background-color: #2b194a;
    border: solid 2px white;
    box-shadow: 0 0 15px #ddd;
    transition: all 0.5s;

    &:hover {
        cursor: pointer;
        transform: scale(0.9);
    }

    &:hover ~ div{
        display: flex;
        transition: 3s;
    }
}
