/**
 * Solicitudes Pragma SA
 */

@import "styles/mixins";

.details {
  @include dynamic-height(column, true);
  gap: 24px;
  padding: 16px;

  h3 {
    padding-bottom: 4px;
    color: var(--color-white);
    font-size: 18px;
    font-weight: 400;
  }

  .container-detail{
    display: flex;
    align-items: center;
    gap: 18px;
  }

  .id-title {
    font-size: 16px;
    font-weight: 700;
    color: var(--color-white);
  }
  
  .container-detail-id{
    background-color: var(--color-shadow-white-primary);
    border-radius: 50%;
    min-width: 64px;
    height: 64px;
    align-items: center;
    justify-content: center;
    display: flex;
  }

  .id-data {
    font-size: 21px;
    font-weight: 600;
  }
  
  .actions {
    display: flex;
    justify-content: center;
    gap: 12px;
    margin: 12px 0;
  }
}

