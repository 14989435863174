/**
 * Solicitudes Pragma SA
 */

 @import "styles/mixins";

 .attached-files {
   @include dynamic-height(column, true);
   gap: 18px;
   padding: 0 16px 0 0;
 
   .module {
     display: flex;
     flex-direction: column;
     gap: 18px;
 
     padding: 12px;
     border: 1px solid var(--color-gray);
     border-radius: 8px;
 
 
     h3 {
       padding-bottom: 4px;
       border-bottom: 1px solid var(--color-comp);
       color: var(--color-white);
       font-size: 16px;
       font-weight: 400;
     }
 
     ul.file-list {
       display: flex;
       flex-wrap: wrap;
       gap: 18px;
       list-style: none;
       padding: 0;
       margin: 10px 0 16px;
 
       li {
         display: flex;
         align-items: center;
         background: rgba(255 255 255 / 8%);
         border-radius: 10px;
         padding: 5px 10px;
         width: 185px;
         justify-content: space-between;
         
         .file-name{
           color: var(--color-white);
           white-space: nowrap;
           overflow: hidden;
           text-overflow: ellipsis;
         }
 
         .download {
           display: flex;
           align-items: center;
           justify-content: center;
           margin-left: 12px;
           padding: 0;
           min-height: 28px;
           min-width: 28px;
           background: var(--color-primary);
           border-radius: 50%;

           &:focus {
            background: var(--color-secondary);
            box-shadow: 0 0 10px var(--color-primary), inset 0 0 5px rgba(189 189 255 / 30%);
           }
         }
 
         .view {
           display: flex;
           align-items: center;
           justify-content: center;
           margin-left: 12px;
           padding: 0;
           min-height: 28px;
           min-width: 28px;
           background: var(--color-shadow-white-primary);
           border-radius: 50%;

           &:focus {
            background: #8A8AFE;
            box-shadow: 0 0 10 rgba(189 189 255 / 70%), inset 0 0 5px rgba(255 255 255 / 40%);
           }
         }
       }
     }
   }
 
   .note {
    border: 1px solid var(--color-gray);
    box-shadow: inset 0 0 30px rgba(130 130 133 / 30%);
    border-radius: 25px;
    background: rgba(130 130 133 / 5%);
   
     h4 {
      box-shadow: inset 0 0 15px rgba(130 130 133 / 60%);
      border-radius: 25px 25px 0 0;
      padding: 10px 16px;
      background: rgba(130 130 133 / 50%);
      color: var(--color-white);
      font-weight: 500;
     }
   
     p {
       font-size: 12px;
       font-weight: 400;
       color: var(--color-white);
       padding: 22px 16px;
     }
   }
 
   .services {
     display: flex;
     flex-direction: column;
     gap: 12px;
   }
 }
 