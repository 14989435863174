/**
 * Solicitudes Pragma SA
 */

@import "styles/mixins";

.facilitate-supports {
  @include dynamic-height(column, true);
  gap: 24px;
  padding: 16px;
}

.container-support{
  display: flex;
  align-items: center;
  gap: 18px;
}

.id-title {
  font-size: 16px;
  font-weight: 700;
  color: var(--color-white);
}

.container-support-id{
  background-color: var(--color-shadow-white-primary);
  border-radius: 50%;
  min-width: 64px;
  height: 64px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.id-data {
  font-size: 21px;
  font-weight: 600;
}
