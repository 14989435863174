/**
 * Solicitudes Pragma SA
 */

@import "styles/mixins";

.container {
  @include dynamic-height(column, true);
  gap: 18px;
  padding: 16px;
}
