/**
 * Solicitudes Pragma SA
 */

.money {
  display: flex;
  flex-direction: column;
  gap: 12px;

  h3 {
    margin: 0;
    color: var(--color-white);
    padding-bottom: 4px;
    font-size: 18px;
    font-weight: 400;
  }

  form {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;

    > label {
      min-width: 203px;
    }

    .transport,
    .food,
    .others,
    .currency {
      flex: 3;
    }

    .total {
      flex: 1;
    }
  }
  
  .cash{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
  }
}
