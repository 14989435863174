/**
 * Solicitudes Pragma SA
 */

.info {
  display: flex;
  flex-direction: column;
  gap: 12px;

  h3 {
    padding-bottom: 4px;
    border-bottom: 1px solid var(--color-comp);
    color: var(--color-white);
    font-size: 18px;
    font-weight: 400;
  }

  form {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;

    .type,
    .document,
    .date-consignment {
      flex: 1;
      min-width: 208px;
      color: var(--color-white);
    }

    .approver {
      flex: 2;
      min-width: 180px;
    }

    .city {
      flex: 3;
      min-width: 180px;
    }
  }
}
