/**
 * Solicitudes Pragma SA
 */

.deny {
  width: 100%;

  textarea {
    min-height: 80px;
  }
}
