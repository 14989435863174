/**
 * Solicitudes Pragma SA
 */

.menu-facilitator {
  display: flex;
  align-items: center;
  gap: 12px;

  > img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }

  .info {
    display: flex;
    flex-direction: column;

      
    .days {
      margin-top: 2px;
      font-size: 12px;
    }
  }
}
