/**
 * Solicitudes Pragma SA
 */

.filter {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin: 16px 0;

  .button {
    margin-top: 25px;
  }

  .row {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 12px;

    > * {
      flex: 1;
    }

    .document {
      min-width: 150px;
    }
  }
}
