/**
 * Solicitudes Pragma SA
 */

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;

  width: 120px;

  &:first-child {
    margin-left: auto;
  }

  &:last-child {
    margin-right: auto;
  }

  .draw {
    display: flex;
    align-items: center;
    justify-content: center;

    .line {
      width: 48px;

      &[data-line='inactive'] {
        border: 1px solid rgba(255 255 255 / 30%);
      }

      &[data-line='error'] {
        border-bottom: 2px solid var(--color-error);
      }

      &[data-line='active'] {
        border-bottom: 2px solid var(--color-white);
      }
    }

    .status {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 25px;
      min-width: 25px;
      border-radius: 50%;
      border: 2px solid rgba(255 255 255 / 30%);
    }

    .status[data-theme='active'] {
      box-shadow: 0 3px 10px var(--color-shadow-white-primary);
      background-color: var(--color-white);
      color: var(--color-white);
    }

    .status[data-theme='error'] {
      box-shadow: 0 0 10px var(--color-shadow-error-primary),
        inset 0 0 10px var(--color-shadow-error-secondary);
      background-color: var(--color-error);
      color: var(--color-white);
    }

    .status[data-theme='semiactive'] {
      background-color: rgba(255 255 255 / 10%);
      border: 2px solid var(--color-white);
    }
  }

  .text {
    color: var(--color-white);
    width: 100px;
    bottom: 0;
    left: 57px;
    text-align: center;
    font-size: 11px;
    line-height: 1.1;
  }
}
