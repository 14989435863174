/**
 * Solicitudes Pragma SA
 */

.state {
  display: flex;
  align-items: center;
  gap: 4px;

  font-weight: 600;
  font-size: 13px;

  &[data-state='pendiente'],
  &[data-state='en_revision'],
  &[data-state='proceso_liquidacion'],
  &[data-state='por_legalizar'] {
    color: var(--color-state-label);
  }

  &[data-state='aprobada'],
  &[data-state='gestionada'],
  &[data-state='finalizado'],
  &[data-state='liquidada'] {
    color: var(--color-shadow-success-secondary);
  }

  &[data-state='rechazada'],
  &[data-state='cancelada'],
  &[data-state='ocultar'] {
    color: var(--color-error);
  }

  img {
    height: 16px;
    margin-left: 4px;
  }
}
