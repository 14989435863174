/**
 * Solicitudes Pragma SA
 */
 ::placeholder{
  color: white;
  opacity: 1;
 }

.supports {
  display: flex;
  flex-direction: column;
  gap: 18px;

  h3 {
    color: var(--color-white);
    padding-bottom: 4px;
    font-size: 18px;
    font-weight: bold;
  }

  .reminder {
    padding: 16px 22px;
    border-radius: 12px;
    color: var(--color-secondary);
    font-size: 15px;
    background-color: var(--color-shadow-white-primary);
    font-weight: 400;
  }

  .descripcion-factura{
    display: block;
    background: none;
    border: none;
    color: white;
    text-indent: 0;
    width: 100%;
    height: auto;
  }

  .actions {
    display: flex;
    gap: 8px;

    button {
      min-height: 24px;
      width: 24px;
      padding: 0;
    }
  }

  .upload {
    align-self: flex-end;
  }
}

