/**
 * Solicitudes Pragma SA
 */
 
.details {
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.fields {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;

  >* {
    flex: 1;
  }
}

.actions {
  flex: 4;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 12px;
}

.cancel {
  min-height: 24px;
  width: 24px;
  padding: 0;
  margin: auto;
}

.upload{
  background-image: url('../../../../assets/images/attach.png');
  background-repeat: no-repeat;
  background-size: 10px;
  background-position: center;
  align-items: center;
  min-height: 25px;
  width: 25px;
  padding: 0;
  margin: auto;
}
