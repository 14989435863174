/**
 * Solicitudes Pragma SA
 */

.refresh {
  display: flex;
  justify-content: flex-start;
  
  .button {
    margin: 18px 0;
  }
}

.state {
  display: flex;
  flex-direction: column;

  min-width: 150px;
  font-weight: 500;
  padding-left: 44px;

  .state-info {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    font-size: 14px;
    color: var(--color-white);
    font-weight: 600;
  }

  .details {
    align-self: flex-end;
    min-height: unset;

    margin-top: auto;
    font-size: 13px;
  }
}

.button-details{
  margin-left: auto;
}
