/**
 * Solicitudes Pragma SA
 */

.tickets {
  display: flex;
  flex-direction: column;
  gap: 12px;

  h3 {
    padding-bottom: 4px;
    color: var(--color-white);
    font-size: 18px;
    font-weight: 400;
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 12px;

    align-items: flex-end;

    @media (max-width: 800px) {
      flex-wrap: wrap;
    }

    .type {
      display: flex;
      align-self: flex-start;
      gap: 12px;
      color: var(--color-tertiary);
    }

    .sizes {
      display: flex;
      gap: 32px;
      justify-content: center;
      flex-wrap: wrap;
      margin: 12px 0;
      padding: 0 2px 0 0;

      .description-size {
        display: flex;
        flex-direction: column;
        font-size: 11px;

        .list {
          padding-inline-start: 16px;
        }

        span {
          display: block;
        }
      }
    }

    .inputs {
      display: flex;
      gap: 12px;
      width: 100%;

      .from,
      .to {
        min-width: 140px;
      }

      @media (max-width: 800px) {
        flex-wrap: wrap;
        flex: 1;
      }

      > label {
        flex: 1;
      }

      .dates {
        width: 100%;
        flex: 2;
      }

      .date-hour {
        display: flex;
        width: 100%;
        gap: 8px;

        @media (max-width: 410px) {
          flex-direction: column;
        }

        .start-date,
        .return-date,
        .start-hour,
        .return-hour {
          min-width: 140px;
        }

        &:nth-child(2) {
          margin-top: 8px;
        }
      }
    }
  }

  .other-services {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .toggle-description {
      display: flex;
      flex-direction: column;
      gap: 3px;
  
      p {
        margin: 0;
        font-size: 11px;
        color: var(--color-white);
      }
    }
  }

  .reminder {
    padding: 16px 22px;
    border-radius: 12px;
    color: var(--color-secondary);
    font-size: 15px;
    background-color: var(--color-shadow-white-primary);
    font-weight: 400;
  }
}

.delete {
  min-height: 24px;
  width: 24px;
  padding: 0;
  margin: auto;
}
