/**
 * Solicitudes Pragma SA
 */

.add {
  margin-top: 25px;
  padding: 9px;
  max-height: 24px;
  max-width: 24px;
}

.business,
.client {
  flex: 2;
  min-width: 180px;
}

.percent {
  flex: 1;
  min-width: 100px;
}

.row {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  flex-flow: row wrap;
}

.delete {
  align-self: flex-end;
  min-height: 32px;
  width: 32px;
  padding: 0;
  margin-bottom: 12px;
}
