/**
 * Solicitudes Pragma SA
 */

.reports {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  gap: 12px;

  .select {
    max-width: 350px;
  }
}

.download {
  margin-bottom: 10px;
}
