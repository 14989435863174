/**
 * Solicitudes Pragma SA
 */

.translation {
  display: flex;
  align-items: center;

  .icon {
    font-size: 22px;
    margin: 0 4px;
    color: var(--color-white);
    min-width: 18px;
  }

  .option {
    padding: 0 8px;
    font-weight: 400;
    font-size: larger;
    color: var(--color-white);
  }

  .active {
    font-weight: 700;
    color: var(--color-white);
  }
}

.translation[data-align="center"] {
  justify-content: center;
}

.translation[data-align="left"] {
  justify-content: flex-start;
  padding-left: 12px;
}

.translation[data-align="right"] {
  justify-content: flex-end;
  padding-right: 12px;
}

.translation[data-size="small"] {
  font-size: 11px;

  .icon {
    font-size: 19px;
  }
}
