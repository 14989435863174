/**
 * Solicitudes Pragma SA
 */

.filter {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 12px;
  margin: 16px 0;

  > label {
    min-width: 150px;
    flex: 2;
  }

  .find {
    flex: 1;
    min-width: 80px;
  }
}
