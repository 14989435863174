/**
 * Solicitudes Pragma SA
 */

.search{
  display: flex;
  justify-content: flex-start;
  margin-bottom: 24px;

  form {
    display: flex;
    align-items: center;
    gap: 12px;
    margin: 18px 0;
    width: 600px;
  }
}
