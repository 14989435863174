/**
 * Solicitudes Pragma SA
 */

@import "styles/mixins";

.history {
  @include dynamic-height(column);
  gap: 18px;
  padding-bottom: 16px;
}

.actions {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 12px;
  margin-top: 24px;
}

.cancel {
  min-height: 24px;
  width: 24px;
  padding: 0;
}

.icon {
  margin: auto;
}
