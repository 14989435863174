/**
 * Solicitudes Pragma SA
 */

.refresh {
  display: flex;
  justify-content: flex-start;

  .button {
    margin: 18px 0;
  }
}

.state-info {
  display: flex;
  flex-direction: column;
  padding-left: 44px;
  row-gap: 4px;
  min-width: 150px;

  font-weight: 600;
  font-size: 14px;
  color: var(--color-white);
}

.details{
  margin-left: auto;
}
