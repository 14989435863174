/**
 * Solicitudes Pragma SA
 */

.filter {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 12px;
  margin: 16px 0;

  > label {
    flex: 2;
    min-width: 150px;
    margin-bottom: 12px;
  }

  .username {
    flex: 2;
    display: flex;
    gap: 5px;
    min-width: 200px;
  }

  .document {
    flex: 4;
    display: flex;
    gap: 5px;
  }

  .search {
    margin-bottom: 10px;
  }

  .authorizer {
    flex: 4;
    display: flex;
    gap: 5px;

    .search {
      align-self: flex-end;
      min-height: 32px;
      min-width: 32px;
      padding: 0;
    }
  }
}
