/**
 * Solicitudes Pragma SA
 */

@import 'styles/mixins';

.tab {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  padding: 0 16px;
}

.container {
  display: flex;
  flex-direction: row;
}

.wrap {
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  gap: 24px;
  width: 100%;
  margin-bottom: 18px;
  border-bottom: 1px solid var(--color-primary);

  @media (max-width: 500px) {
    flex-direction: column;
    gap: 15px;
  }
}

.title {
  background-color: var(--color-background);
  text-align: left;
  font-size: 1.4em;
  font-weight: 400;
  color: var(--color-white);
  line-height: 1.1;
  opacity: 1;
  cursor: pointer;
  border: 0;

  &:hover {
    font-weight: 700;
  }

  @media (max-width: 768px) {
    font-size: 1.2em;
  }
}

.active {
  cursor: default;
  font-weight: 700;
}

.active-custom {
  font-weight: 400;
}

.content {
  flex: 1;
  height: 100%;
  margin: 18px 0 0;
  display: flex;
  flex-direction: column;
}

.scrollable {
  @include scrollbar;
}

.not-scroll {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.notifications {
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: rgba(255 255 255 / 10%);
  border-radius: 12px;
  box-shadow: inset 0 0 6px rgba(255 255 255 / 20%);

  border: 1px solid var(--color-white);
  height: 24px;
  min-width: 24px;
  margin-left: 7px;
  padding-top: 1px;

  font-size: 10px;
  font-weight: 600;
  color: var(--color-white);
}

.lighting {
  width: 100%;
  border-bottom: 3px solid var(--color-white);
  box-shadow: 0 -3px 11px var(--color-shadow-white-primary);
  border-radius: 120px;
  margin-top: 8px;
}
