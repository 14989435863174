/* stylelint-disable selector-class-pattern */

/**
 * Solicitudes Pragma SA
 */

@import '~styles/mixins';

.react-select {
  .react-select__control {
    background-color: rgba(100 41 205 / 5%);
    border: 1px solid var(--color-primary);
    border-radius: 50px;
    height: 32px;
    min-height: 32px;
    cursor: pointer;

    &:hover {
      box-shadow: 0 0 10px var(--color-primary);
      border: 1px solid var(--color-primary);
    }

    &:focus-within {
      box-shadow: inset 0 0 10px var(--color-primary);
      border: 1px solid var(--color-primary);
    }
  }

  &[data-disabled] .react-select__control {
    border: 1px solid var(--color-disabled-primary);
    background-color: transparent;
  }

  .react-select__input-container {
    color: var(--color-white);
    font-weight: 400;
  }

  .react-select__placeholder {
    color: var(--color-gray);
    font-weight: 400;
  }

  .react-select__value-container {
    padding: 0 8px;

    .react-select__single-value {
      color: var(--color-white);
      font-family: inherit;
      font-weight: 400;
    }
  }

  .react-select__menu {
    border-radius: 15px;
    padding: 12px 0;
    z-index: 5;
    border: 1px solid var(--color-primary);
    box-shadow: 0 0 10px var(--color-primary);
    background-color: var(--color-background);
    font-weight: 400;
  }

  .react-select__menu-list {
    @include scrollbar;
    max-height: 200px;
    padding: 0;
  }

  .react-select__option {
    background: rgba(100 41 205 / 5%);
    color: var(--color-white);

    &.react-select__option--is-focused,
    &:hover {
      color: var(--color-white);
      background-color: var(--color-primary);
      box-shadow: inset 0 0 10px rgba(189 189 255 / 50%);
      cursor: pointer;
    }
  }

  .react-select__option--is-selected {
    background: rgba(100 41 205 / 5%);
    color: var(--color-white);

    &:hover {
      background-color: var(--color-primary);
      box-shadow: inset 0 0 10px rgba(189 189 255 / 50%);
      color: var(--color-white);
      cursor: pointer;
    }
  }

  .react-select__indicator {
    padding: 0 8px 0 6px;
    border-radius: 0 20px 20px 0;
    height: 100%;
    align-items: center;
  }

  &[data-disabled] .react-select__indicator {
    background-color: var(--color-disabled-primary);
  }

  .react-select__dropdown-indicator {
    color: var(--color-white);
    background-color: var(--color-primary);

    &:hover {
      color: var(--color-white);
    }
  }

  .react-select__loading-indicator {
    color: var(--color-white);

    &:hover {
      color: var(--color-white);
    }
  }

  &[data-label] .react-select__control {
    margin-top: 12px;
  }

  &[data-error] {
    .react-select__control {
      border: 1px solid var(--color-error);
      box-shadow: inset 0 0 10px var(--color-error);
    }

    .react-select__dropdown-indicator {
      color: var(--color-error);
    }

    .react-select__indicator {
      background-color: transparent;
    }
  }
}
