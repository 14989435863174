/**
 * Solicitudes Pragma SA
 */

@import 'styles/mixins';

.paid-request {
  @include dynamic-height(column);
  gap: 18px;
  padding: 0 16px;

  h3 {
    width: 100%;
    color: var(--color-white);
    padding-bottom: 4px;
    font-size: 16px;
    font-weight: 400;
  }

  .form {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;

    .doc-type {
      flex: 1;
    }

    .document,
    .approver,
    .days,
    .pay-date {
      flex: 1;
    }

    .container-document {
      display: flex;
    }

    .document {
      > div {
        border-radius: 50px 0 0 50px;
        border-top: 1px solid var(--color-primary);
        border-left: 1px solid var(--color-primary);
        border-bottom: 1px solid var(--color-primary);
        border-right: none;

        > input {
          border-radius: 0;
        }
      }
    }

    .document-custom {
      > div {
        border-top: 1px solid var(--color-disabled-primary);
        border-left: 1px solid var(--color-disabled-primary);
        border-bottom: 1px solid var(--color-disabled-primary);
      }
    }

    .document-focus{
      > div {
        &:focus-within {
          box-shadow: none;
        }
    
        &:hover {
          box-shadow: none;
        }
      }
    }

    .search {
      min-height: 0;
      min-width: 0;
      margin-top: 28px;
      padding: 0;
      height: 32px;
      width: 30px;
      border-radius: 0 50px 50px 0;
      
      &:hover {
        box-shadow: none;
      }
    }
  }

  .create {
    width: fit-content;
  }

  .line {
    width: 100%;
    border-bottom: 1px solid var(--color-comp);
  }
}

@media (max-width: 627px) {
  .form {
    flex-direction: column;
  }
}
