/**
 * Solicitudes Pragma SA
 */

.notes {
  display: flex;
  flex-direction: column;

  border-radius: 8px;

  h4 {
    padding: 8px 12px;
    border-radius: 8px 8px 0 0;
    background: var(--color-primary);
    color: var(--color-white);
  }

  ul {
    padding: 18px 32px;
    border: 1px solid var(--color-primary);
    border-top: none;
    border-bottom: none;
    color: var(--color-white);
  }
  
  .help {
    padding: 8px 12px;
    border-radius: 0 0 8px 8px;
    border: 1px solid var(--color-primary);
    border-top: none;
    background: var(--color-comp);
    color: var(--color-white);
    font-size: 12px;
    font-weight: 400;
    
    .help-link {
      color: var(--color-white);
      font-weight: 700;
    }
  }
}
