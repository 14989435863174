/**
 * Solicitudes Pragma SA
 */

.tickets {
  display: flex;
  flex-direction: column;
  gap: 12px;

  .sizes{
    display: flex;
    gap: 20px;
    justify-content: center;
    margin: 16px 0;

    .description-size {
      display: flex;
      flex-direction: column;
      font-size: 11px;

      .list {
        padding-inline-start: 16px;
      }

      span {
        display: block;
      }
    }
  }

  h2 {
    padding-bottom: 4px;
    color: var(--color-white);
    font-size: 18px;
    font-weight: 400;
  }

  .other-services {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .toggle-description {
      display: flex;
      flex-direction: column;
      gap: 3px;
  
      p {
        margin: 0;
        font-size: 11px;
        color: var(--color-text);
      }
    }
  }
}
