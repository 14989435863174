/**
 * Solicitudes Pragma SA
 */

@import "styles/mixins";

.compensation {
  @include dynamic-height(column);
  gap: 18px;
  padding: 0 16px;
}

.actions {
  display: flex;
  justify-content: flex-start;
  gap: 12px;
  margin-top: 24px;
}

.success {
  color: var(--color-success);
  font-size: 2em;
}

.secondary {
  color: var(--color-gray);
  font-size: 2em;
}

.icon {
  margin: auto;
}
