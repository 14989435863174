/**
 * Solicitudes Pragma SA
 */

@import "styles/mixins";

.admin-reports {
  @include dynamic-height(column);
  gap: 18px;
  padding-bottom: 16px;

  .actions {
    display: flex;
    justify-content: flex-start;
    gap: 12px;
    margin-top: 24px;
  }
}

.modal-filter {
  margin-top: 12px;
}
