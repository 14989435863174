/* stylelint-disable no-descending-specificity */

/**
 * Solicitudes Pragma SA
 */

.menu {
  display: flex;
  flex-direction: column;
  background-color: var(--color-background);

  .option {
    @media (max-width: 541px) {
      display: none;
    }
  }

  .options {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 18px 8px;

    .section {
      padding: 8px 0;
      color: var(--color-white-transparentize-5);
      text-decoration: none;
    }

    .selected {
      color: var(--color-white);
    }

    @media (min-width: 801px) {
      display: none;
    }
  }

  .section {
    padding: 0 34px 24px;
    font-size: 24px;
    font-weight: 400;
    color: var(--color-white);
    margin-top: 24px;
  }

  .section1 {
    @media (max-width: 800px) {
      display: none;
    }
  }

  .link {
    display: flex;
    text-decoration: none;
    color: var(--color-white);
    cursor: pointer;

    &:hover {
      font-weight: 700;
    }

    .item {
      display: flex;
      align-items: center;
      gap: 12px;
      width: 100%;
      margin: 0 32px 0 0;
      padding: 12px 0;
      font-size: 15px;
      
      .lighting {
        height: 100%;
        border-left: 3px solid var(--color-white);
        box-shadow: 2px 0 10px var(--color-shadow-white-primary);
        border-radius: 120px;
        margin-right: 17px;
      }
      
      .lighting-off {
        height: 100%;
        border-left: 3px solid transparent;
        border-radius: 120px;
        margin-right: 17px;
      }

      .icon-active {
        height: 34px;
        min-width: 34px;

        > img {
          width: 100%;
          height: 100%;
        }
      }
      
      .icon-disabled {
        height: 34px;
        min-width: 34px;

        > img {
          width: 100%;
          height: 100%;
        }
      }

      .notifications {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 1px;
        padding-top: 1px;

        background-color: rgba(255 255 255 / 10%);
        border-radius: 12px;
        box-shadow: inset 0 0 6px rgba(255 255 255 / 20%);

        border: 1px solid var(--color-white);
        height: 24px;
        min-width: 24px;
        margin-left: 7px;

        font-size: 10px;
        font-weight: 600;
        color: var(--color-white);
      }
    }

    .last-item {
      padding-bottom: 0;
    }
  }
  
  .active-link {
    cursor: default;
    font-weight: 700;
    
    &:hover {
      font-weight: 700;
    }
  }

  .active-custom {
    font-weight: 400;

    &:hover {
      font-weight: 400;
    }
  }
}
