/* stylelint-disable no-descending-specificity */

/**
 * Solicitudes Pragma SA
 */

.calendar-input {
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--color-white);
  font-weight: 700;

  &:focus-within > div:first-child > div,
  &:not([data-disabled]):focus > div:first-child > div {
    box-shadow: inset 0 0 10px var(--color-primary);
    border: 1px solid var(--color-primary);
  }

  &[data-disabled] {
    // Disabled
    opacity: 0.7;
    cursor: default;
  }

  &[data-error] {
    &:focus-within > div:first-child > div,
    &:not([data-disabled]):focus > div:first-child > div {
      box-shadow: inset 0 0 10px var(--color-error);
      border: 1px solid var(--color-error);
    }

    .helper {
      color: var(--color-error);
    }

    > span,
    input {
      color: var(--color-gray);
    }
  }
}

.popper {
  box-shadow: 0 8px 8px var(--color-black-transparentize-8);
}

.helper {
  align-self: flex-end;
  margin-top: 12px;
  font-size: 0.9em;
}

.calendar-inline {
  display: flex;
  flex-direction: column;
  font-weight: 500;
  color: var(--color-white);

  &[data-disabled] {
    opacity: 0.7;
    cursor: default;
  }

  &[data-error] {
    color: var(--color-white);

    .helper {
      color: inherit;
    }
  }

  .helper {
    align-self: center;
  }
}
