/**
 * Solicitudes Pragma SA
 */

 @import "styles/mixins";

 .search {
  @include dynamic-height(column);
  gap: 18px;
  padding: 4px;

  form {
    display: flex;
    align-items: flex-end;
    gap: 12px;
  }

  .content {
    @include dynamic-height(column, true);
  } 
}

  .state {
    display: flex;
    flex-direction: column;
    padding-left: 44px;
    min-width: 150px;
    row-gap: 4px;
  
    font-weight: 600;
    font-size: 14px;
    color: var(--color-white);
  }
  