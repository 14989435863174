/**
 * Solicitudes Pragma SA
 */
@use 'sass:math';

$time-1: 10s;
$time-2: 10s;
$time-3: 10s;

.login {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.shadow {
  opacity: 80;
  transition: opacity 0.5s linear;
  position: absolute;
  content: '';
  top: 20px;
  left: 20px;
  right: 0;
  z-index: -1;
  height: 80%;
  width: 80%;
  transform: scale(0.9) translateZ(0);
  filter: blur(45px);
  border-radius: 100px;
  background: linear-gradient(to left, #6429cd, #dd52dd);
  background-size: 200% 200%;
  animation: boxshadow 1.75s, animate-glow 2.5s linear infinite;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;

  h1 {
    text-align: center;
    font-weight: 600;
    font-size: 36px;
    color: var(--color-white);
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    top: 42px;
    gap: 8px;

    width: 340px;
    height: 58px;

    font-size: 18px;
    font-weight: 500;
  }
}

@function particles($max) {
  $val: (0 0 0 0 #99999B);
  $vw: 0;
  $vh: 0;
  @for $i from 1 through $max {
    $vw: math.random(100) - 50 + vw;
    $vh: math.random(100) - 50 + vh;
    $val: #{$val}, #{$vw} #{$vh} #535355, #{$vw} #{$vh} 3px 0 #828282;
  }
  @return $val;
}

@mixin particles($max) {
  box-shadow: particles($max);
  border-radius: 50%;
}

.wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
}

.first {
  position: fixed;
  top: 50%;
  left: 50%;
  height: 3px;
  width: 3px;
  @include particles(75);
  animation: zoom $time-1 linear forwards infinite;
}

.second {
  position: fixed;
  top: 50%;
  left: 50%;
  animation: zoom $time-2 linear forwards infinite;
  @include particles(100);
  height: 2px;
  width: 2px;
}

.third {
  position: fixed;
  top: 50%;
  left: 50%;
 animation: zoom $time-3 linear forwards infinite;
  @include particles(75);
  height: 1px;
  width: 1px;
}

.logo {
  position: relative;
  width: 250px;
  height: 250px;
  margin-bottom: 60px;
}

.ball {
  opacity: 0;
  width: 250px;
  height: 250px;
  position: absolute;
  border-radius: 50%;
  border: 12px solid var(--color-white);
  transform: scale(0);

  animation: big-circle 1s 1s forwards;
}

.one {
  opacity: 0;
  width: 24px;
  height: 24px;
  background: var(--color-white);
  border-radius: 50%;
  position: absolute;
  margin: auto;
  top: 0;
  left: 95px;
  bottom: 48px;

  animation: little-circle 1s forwards;
}

.two {
  border: 10px solid var(--color-white);
  width: 90px;
  height: 90px;
  border-radius: 50%;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 49px;
  left: 77px;
}

.three {
  opacity: 0;
  border: 10px solid var(--color-white);
  width: 62px;
  height: 62px;
  border-radius: 50%;
  position: absolute;
  margin: auto;
  top: 0;
  left: 76px;
  bottom: 46px;

  animation: medium-circle 1s forwards;
}

@keyframes big-circle {
  from {
    transform: scale(0);
    opacity: 0;
  }

  to {
    transform: scale(100%);
    opacity: 1;
  }
}

@keyframes medium-circle {
  0% {
    left: 76px;
    bottom: 46px;
  }

  100% {
    left: 103px;
    bottom: -93px;
    opacity: 1;
  }
}

@keyframes little-circle {
  0% {
    left: 95px;
    bottom: 48px;
  }

  100% {
    left: 144px;
    bottom: 135px;
    opacity: 1;
  }
}

@keyframes animate-glow {
  0% {
    background-position: 0% 50%;
  }
  
  100% {
    background-position: 200% 50%;
  }
}

@keyframes zoom {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.5);
  }
}

@keyframes boxshadow {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  
  80% {    
    transform: scale(0);
    opacity: 0;
  }

  100% {
    transform: scale(100%);
    opacity: 1;
  }
}
