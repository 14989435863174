/**
 * Solicitudes Pragma SA
 */

.hosts {
  display: flex;
  flex-direction: column;
  gap: 12px;

  h2 {
    padding-bottom: 4px;
    color: var(--color-white);
    font-size: 18px;
    font-weight: 400
  }
}
