/**
 * Solicitudes Pragma SA
 */

.hosts {
  display: flex;
  flex-direction: column;
  gap: 12px;

  h3 {
    padding-bottom: 4px;
    color: var(--color-white);
    font-size: 18px;
    font-weight: 400;
  }

  form {
    display: flex;
    flex-wrap: wrap;
    gap: 14px;

    .city {
      flex: 3;
    }

    .date-in,
    .date-out {
      flex: 2;
      min-width: 170px;
    } 

    .actions {
      flex: 4;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      gap: 12px;

      .host-button-add {
        min-width: 115px;
      }
    }

  }

  .delete {
    min-height: 24px;
    width: 24px;
    padding: 0;
    margin: auto;
  }
}
