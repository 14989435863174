/**
 * Solicitudes Pragma SA
 */

.form {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;

  .country,
  .business {
    flex: 1;
    min-width: 140px;
  }

  .notes {
    width: 100%;

    textarea {
      min-height: 80px;
    }
  }  
}
