/* stylelint-disable no-descending-specificity */

/**
 * Solicitudes Pragma SA
 */

.card {
  display: flex;
  flex-direction: column;
  overflow: hidden; 

  border: 1px solid var(--color-primary);
  border-radius: 25px;
  background: rgba(100 41 205 / 5%);
  box-shadow: inset 0 4px 30px rgba(100 41 205 / 25%);

  margin-bottom: 18px;

  &:last-child {
    margin-bottom: 0;
  }
}

.overflow {
  overflow: visible;
}

.header {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: fit-content;
  padding: 16px 20px;


  border-bottom: 1px solid var(--color-primary);
  backdrop-filter: blur(70px);
  border-radius: 25px 25px 0 0;

  background: rgba(100 41 205 / 30%);
  font-weight: 400;
  color: var(--color-white);
  text-align: left;
  align-items: center;

  .user {
    display: flex;
    align-items: center;
    gap: 12px;

    @media (max-width: 540px) {
      grid-area: 1 / 1 / 1 / span 2;
    }

    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }

    .info {
      display: flex;
      flex-direction: column;
      row-gap: 4px;

      h3 {
        font-size: 14px;
        font-weight: 600;
        white-space: nowrap;
      }
  
      .email {
        font-weight: 400;
        font-size: 12px;
      }
    }
  }

  .request {
    text-align: right;
    font-size: 12px;
    
    .id {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 4px;
      font-size: 14px;
      font-weight: 600;
      white-space: nowrap;
    }
    
    .date {
      font-weight: 400;
      font-size: 12px;
    }

    @media (max-width: 540px) {
      grid-area: 2 / 1 / 2 / span 2;
      text-align: center;

      .id {
        justify-content: center;
      }
    }
  }

  .tag {
    grid-area: 2 / 1 / 2 / span 2;
    font-size: 12px;
    color: var(--color-white);
    font-weight: 600;
    text-align: center;
    padding: 4px;

    @media (max-width: 540px) {
      grid-area: 4 / 1 / span 2 / 4;
    }
  }
}


.body {
  display: flex;
  justify-content: space-between;
  padding: 24px;

  @media (max-width: 540px) {
    flex-flow: column;
  }

  .info {
    display: flex;
    flex-wrap: wrap;
    row-gap: 40px;
    column-gap: 50px;
  
    .data {
      display: flex;
      flex-direction: column;
      font-weight: 600;
      font-size: 14px;
      color: var(--color-white);
      row-gap: 4px;

      .value {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
  
        color: var(--color-white);
        font-weight: 400;
        font-size: 12px;
      }
    }
  }
}

.actions {
  display: flex;
  justify-content: flex-start;
  gap: 8px;

  margin-bottom: 12px;
  padding: 8px 24px;
}
