/**
 * Solicitudes Pragma SA
 */

@import 'styles/mixins';

.container {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;

  .show {
    min-height: 30px;
    border-radius: 8px;
    font-size: 13px;
    font-weight: bold;
  }

  .icon {
    color: var(--color-white);
    margin: 5px 10px 5px 0;
  }

}

.checkboxes {
  display: flex;
  justify-content: space-evenly;
  margin-top: 25px;
  flex-flow: row wrap;
}

.wrapper {
  margin-top: 25px;
  display: flex;
  flex-direction: column;

  span {
    color: var(--color-white);
  }

  button{
    margin-top: 20px;
    display: flex;
    align-self: center;
  }
}

.text {
  width: 100%;
  margin-top: 25px;

  textarea {
    min-height: 80px;
    color: var(--color-white);
  }
}
