/**
 * Solicitudes Pragma SA
 */
 
.search{
  display: flex;
  justify-content: flex-start;

  form {
    display: flex;
    align-items: center;
    gap: 12px;
    margin: 18px 0;
    width: 330px;
  }
}

.state-info {
  display: flex;
  flex-direction: column;
  padding-left: 4px;
  min-width: 150px;
  font-size: 12px;
  justify-content: space-between;

  @media (max-width: 900px) {
    justify-content: start;
    padding-left: 0;
      
    .important-custom {
      margin-top: 40px;
    }
  }

  .important {
    display: flex;
    flex-direction: column;
    white-space: nowrap;
    row-gap: 4px;

    @media (max-width: 540px) {
      margin-top: 12px;
    }

    .title-date {
      font-size: 14px;
      font-weight: 600;
      color: var(--color-white);
    }
    
    .date{
      font-size: 12px;
      font-weight: 600;
      color: var(--color-information-tickets);
    }
    
    .title-city{
      font-size: 14px;
      font-weight: 600;
      color: var(--color-white);
    }

    .city{
      font-size: 12px;
      font-weight: 600;
      color: var(--color-information-tickets);
    }
  }
}

.details{
  margin-left: auto;
}
