/* stylelint-disable selector-class-pattern */

.react-datepicker {
  background-color: var(--color-background);
  border: 1px solid var(--color-primary);
  box-shadow: inset 0 0 30px rgba(100 41 205 / 50%);
  border-radius: 25px;
  font-size: 13px;
  color: var(--color-text);
  font-family: Poppins, sans-serif;

  .react-datepicker__header {
    background-color: transparent;
    border-bottom: 1px solid var(--color-primary);
  }

  .react-datepicker__month-container {
    padding: 8px;
  }

  .react-datepicker__current-month,
  .react-datepicker__day-name {
    color: var(--color-white);
    font-weight: 700;
    margin: 0;
    min-height: 28px;
    min-width: 32px;
  }

  .react-datepicker__navigation-icon::before {
    border-color: var(--color-white);
    top: 15px;
  }

  .react-datepicker__day--disabled {
    color: var(--color-white);
    opacity: 0.2;
  }

  .DayPicker-Caption {
    margin-top: 2px;
    font-size: 12px;
    text-align: center;
  }

  .react-datepicker__day {
    color: var(--color-white);
    margin: 0;
    min-height: 11px;
    min-width: 32px;
  }

  .react-datepicker__day--today {
    text-decoration: underline;
  }

  .react-datepicker__day:not(.react-datepicker__day--disabled):not(.react-datepicker__day--in-range):hover {
    background-color: var(--color-primary);
    color: var(--color-white);
    border-radius: 0;
  }

  .react-datepicker__day:not(.react-datepicker__day--in-selecting-range):not(.react-datepicker__day--in-range):not(.react-datepicker__day--range-start):not(.react-datepicker__day--range-end):hover {
    border-radius: 0;
  }

  .react-datepicker__day--in-selecting-range {
    background-color: rgba(100 41 205 / 80%);
    color: var(--color-white);
    border-radius: 0;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected {
    background-color: var(--color-primary);
    color: var(--color-white);
    border-radius: 50%;
    font-weight: 600;
  }

  .react-datepicker__day--in-range {
    background-color: var(--color-primary);
    color: var(--color-white);
    font-weight: 600;
    border-radius: 0;
  }

  .react-datepicker__day--excluded.react-datepicker__day--in-range {
    background-color: var(--color-primary);
    color: var(--color-white);
  }

  .react-datepicker__day--weekend.react-datepicker__day--in-range {
    background-color: var(--color-primary);
    color: var(--color-white);
  }

  .react-datepicker__day--in-range:not(.react-datepicker__day--excluded):not(.react-datepicker__day--weekend):not(.react-datepicker__day--range-start):not(.react-datepicker__day--range-end) {
    background-color: rgba(100 41 205 / 50%);
    color: var(--color-white);
  }

  .react-datepicker__day--range-start,
  .react-datepicker__day--range-end {
    background-color: var(--color-primary);
    color: var(--color-white);
    opacity: 1;
  }

  .react-datepicker__day--range-start {
    border-radius: 50% 0 0 50%;
  }

  .react-datepicker__day--range-end {
    border-radius: 0 50% 50% 0;
  }

  .react-datepicker__day--range-start.react-datepicker__day--range-end {   
    background-color: var(--color-primary);
    border-radius: 45%;
  }

}

.react-datepicker-wrapper {
  margin-top: 10px;
}

.react-datepicker-popper {
  z-index: 2;
}

.react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle::after,
.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::after {
  border: none;
}

.react-datepicker__input-container {
  width: 100%;

  display: flex;
  align-items: center;
  box-sizing: border-box;
  position: relative;

  height: 32px;
  min-height: 32px;
  padding: 0 12px;
  margin-top: 2px;

  background: rgba(100 41 205 / 5%);
  font-weight: 400;

  border-radius: 50px;
  border: 1px solid var(--color-primary);

  transition: all 0.1s ease;

  &:hover {
    box-shadow: 0 0 10px var(--color-primary);
    border: 1px solid var(--color-primary);
  }

  input {
    width: 100%;
    min-width: 0; // Evita que se desborde del contenedor

    margin: 0;
    padding: 0;

    outline: none;
    border: 0;
    box-sizing: border-box;

    background: transparent;

    color: var(--color-text);
    font-family: inherit;

    &::placeholder {
      color: var(--color-gray);
    }

    &:required:invalid {
      box-shadow: none;
    }
  }
}

.react-datepicker__time-container {
  .react-datepicker__header.react-datepicker__header--time .react-datepicker-time__header {
    color: var(--color-white);
  
  }

  .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
    li.react-datepicker__time-list-item {
      height: unset;
      background-color: var(--color-background);
      padding: 6px;

      &.react-datepicker__time-list-item:hover {
        background-color: var(--color-primary);
        color: var(--color-white);
      }
      
      &.react-datepicker__time-list-item--selected {
        background-color: var(--color-primary);
      }
    }
  }
}

[data-error] .react-datepicker {
  color: var(--color-white);
  background-color: var(--color-background);
  box-shadow: inset 0 0 10px var(--color-error);
  border-color: var(--color-error);

  .react-datepicker__header {
    background-color: transparent;
    border-bottom: 1px solid var(--color-error);
  }

}

[data-error] .react-datepicker__input-container {
  box-shadow: inset 0 0 10px var(--color-error);
  border: 1px solid var(--color-error);
}

