/**
 * Solicitudes Pragma SA
 */

.user {
  display: none;
  align-items: center;
  align-self: center;
  gap: 18px;
  background-color: var(--color-background);

  @media (max-width: 1220px) {
    display: flex;
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }

  img {
    border-radius: 50%;
    min-width: 64px;
    height: 64px;
  }

  .info {
    color: var(--color-white);
    padding: 0 18px;

    @media (max-width: 768px) {
      text-align: center;
    }

    h3 {
      margin: 0;
      font-size: 24px;
    }
  
    h4 {
      margin: 0;
      margin-top: 4px;
      font-size: 14px;
      font-weight: 400;
    }
  }
}
