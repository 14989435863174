/**
 * Solicitudes Pragma SA
 */

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: var(--color-white);
}
