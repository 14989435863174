/**
 * Solicitudes Pragma SA
 */

@import 'styles/mixins';

.template {
  background: var(--color-background);
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  @include scrollbar;
  overflow: overlay;

  .main {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 24px;
    margin-top: 20px;
    margin-bottom: 7px;

    @media (max-width: 540px) {
      align-items: flex-start;
      height: 100%;
      padding: 0;
    }
  }

  .button-pragma {
    background: var(--color-shadow-white-primary);
    border-radius: 15px 0 0 15px;
    min-width: 40px;
    min-height: 130px;
    position: fixed;
    right: 0;
    bottom: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    cursor: pointer;
    
    &:hover {
      box-shadow: 0 0 20px rgba(189 189 255 / 60%), inset 0 0 30px rgba(255 255 255 / 70%);
    }
    
    > p {
      color: var(--color-secondary);
      writing-mode: tb-rl;
      transform: rotate(180deg);
      font-weight: 700;
    }
  }

  .button-scroll {
    background: rgba(100 41 205 / 5%);
    box-shadow: inset 0 0 30px rgba(100 41 205 / 30%);
    border-left: 1px solid var(--color-primary);
    border-top: 1px solid var(--color-primary);
    border-right: 1px solid var(--color-primary);
    border-radius: 25px 25px 0 0;
    min-width: 40px;
    min-height: 40px;
    position: fixed;
    right: 40px;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    animation: button-scroll 0.5s ease 0s 1 normal forwards;

    @media (max-width: 1350px) {
      right: 5px;
    }
    
    &:hover {
      background: rgba(100 41 205 / 50%);
      box-shadow: 0 0 20px rgba(100 41 205 / 70%), inset 0 0 30px rgba(100 41 205 / 30%);
    }
  }
}

.container {
  display: flex;
  height: 100%;
  width: auto;
  background-color: var(--color-background);
  border-radius: 25px;
  box-shadow: 0 6px 10px var(--color-shadow);

  @media (max-width: 540px) {
    border-radius: 0;
    max-height: unset;
  }

  @media (max-width: 800px) {
    width: 100%;
  }

  .page {
    display: flex;
    flex-direction: column;
    width: 926px;
    padding: 24px;

    @media (max-width: 540px) {
      height: 90vh;
    }

    @media (max-width: 900px) {
      width: 100%;
    }

    .title {
      color: var(--color-white);
      padding-bottom: 16px;
      margin: 0 16px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid var(--color-primary);
    }

    .title-custom {
      color: var(--color-white);
      padding-bottom: 8px;
      margin: 0 22px;
      display: flex;
      align-items: center;
    }

    .body {
      @include dynamic-height(column);
      margin-top: 18px;
    }
  }

  .back {
    border-radius: 20px;
    padding: 10px;
    max-height: 24px;
    max-width: 24px;
    margin-right: 16px;
  }
}

@keyframes button-scroll {
  0% {
    visibility: hidden;
    opacity: 0;
    transform: scale(0);
  }
  
  60% {
    visibility: hidden;
    opacity: 0;
    transform: scale(0);
  }

  100% {
    transform: scale(1);
    visibility: visible;
    opacity: 1;
  }
}
