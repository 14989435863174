/**
 * Solicitudes Pragma SA
 */

@import "styles/mixins";

.history {
  @include dynamic-height(column);
  gap: 18px;
}

.state {
  display: flex;
  flex-direction: column;
  padding-left: 44px;
  min-width: 150px;
  row-gap: 4px;
  font-weight: 600;
  font-size: 14px;
  color: var(--color-white);
}
