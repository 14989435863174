/**
 * Solicitudes Pragma SA
 */

.sidebar {
  display: flex;
  flex-direction: column;
  width: 250px;
  height: 100%;
  padding-top: 12px;
  background: var(--color-backgound);
  border-radius: 25px 0 0 25px;
  margin-right: 70px;

  .translation {
    display: none;
    @media (max-width: 900px) {
      display: flex;
    }
  }

  @media (max-width: 1256px) {
    width: 230px;
    margin-right: 50px;
  }
  
  @media (max-width: 1220px) {
    display: none;
    transition: left 0.5s linear;
    padding-top: 48px;
    min-width: 75%;
    z-index: 100;
    height: 100%;
    border-radius: 0;

    &[data-show] {
      display: flex;
      position: fixed;
      top: 0;
      left: 0;
      background-color: var(--color-background);
    }
  }

  @media (max-width: 900px) {
    padding-top: 20px;
  }

  @media (max-width: 768px) {
    padding-top: 24px;
  }
}

.screen {
  position: absolute;
  z-index: 3;

  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  display: none;

  background: var(--color-black-transparentize-7);

  &[data-show] {
    display: block;
  }
}

.footer {
  display: none;
  align-self: flex-end;
  margin: 48px;
  margin-top: auto;

  @media (max-width: 1024px) {
    display: block;
  }
}

.logout[data-ghost] {
  color: var(--color-white);

  &:hover {
    color: var(--color-primary);
  }
}
