/**
 * Solicitudes Pragma SA
 */

.cost-center {
  display: flex;
  flex-direction: column;
  gap: 12px;

  h3 {
    padding-bottom: 4px;
    color: var(--color-white);
    font-size: 18px;
    font-weight: 400;
  }

  .description {
    width: 100%;

    textarea {
      min-height: 80px;
    }
  }
}
