/**
 * Solicitudes Pragma SA
 */

.shimmers {
  display: flex;
  flex-direction: column;
  gap: 18px;
}

table tbody tr.row:nth-child(even) {
  background-color: transparent !important;
}

.shimmer {
  width: 100%;

  border-radius: 8px;
  background-image: linear-gradient(
    90deg,
    var(--color-days) 0%,
    var(--color-days) 40%,
    var(--color-background) 50%,
    var(--color-background) 55%,
    var(--color-days) 65%,
    var(--color-days) 100%
  );

  background-size: 400%;
  animation: shimmer 1500ms infinite;

  cursor: wait;
}

@keyframes shimmer {
  from {
    background-position: 100% 100%;
  }

  to {
    background-position: 0 0;
  }
}
