/**
 * Solicitudes Pragma SA
 */

.user {
  position: relative;

  @media (max-width: 1220px) {
    display: none;
  }
}

.user-menu {
  position: relative;
  padding: 0;
  border-radius: 50%;

  .logo {
    min-width: 42px;
    height: 42px;
    border-radius: 50%;
  }
}

.menu {
  position: absolute;
  padding: 4px;
  width: 150px;
  right: 14px;
}
