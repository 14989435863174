/**
 * Solicitudes Pragma SA
 */

.title {
  color: var(--color-white);
  padding-bottom: 4px;
  font-size: 18px;
  border-bottom: 1px solid var(--color-comp);
  width: 100%;
}

.state {
  background-color: var(--color-white);
  padding: 4px 8px 3px;
  border-radius: 4px;
  color: var(--color-primary);
  margin-left: 8px;
  font-size: 12px;
}

.discount {
  display: flex;
  flex-direction: column;
  gap: 12px;

  h3 {
    color: var(--color-white);
    padding-bottom: 4px;
    font-size: 18px;
    font-weight: bold;
  }

  .checkbox {
    display: flex;
    gap: 12px;
  }
}
