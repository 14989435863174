/**
 * Solicitudes Pragma SA
 */

.loader {
  position: fixed;
  z-index: 100;

  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  background: rgba(19 19 19 / 80%);

  div {
    align-self: center;
    border: 6px solid var(--color-primary);
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }

  div:nth-child(2) {
    animation-delay: -0.5s;
  }

  @keyframes lds-ripple {
    0% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 1;
    }

    100% {
      top: -30px;
      left: -30px;
      width: 100px;
      height: 100px;
      opacity: 0;
    }
  }
}
