/**
 * Solicitudes Pragma SA
 */
.empty-state {
  align-self: center;
  text-align: center;
  padding: 10px;
  color: var(--color-white);
  font-size: 15px;
  font-weight: 400;
}
