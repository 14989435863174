/**
 * Solicitudes Pragma SA
 */

@import "styles/mixins";

.table {
  @include dynamic-height(column, true);
  border: 1px solid var(--color-primary);
  border-radius: 25px;

  table {
    width: 100%;
    border-spacing: 0;
    font-size: 12px;
    background-color: rgba(100 41 205 / 5%);
    border-bottom: 1px solid var(--color-primary);
    box-shadow: inset 0 4px 80px rgba(100 41 205 / 25%);
    color: var(--color-white);
  
    thead {
      background: rgba(100 41 205 / 50%);
      border: 1px solid var(--color-primary);
      box-shadow: inset 0 0 15px var(--color-primary);
    }

    thead th {
      border-right: 1px solid var(--color-primary);
      text-align: left;
      padding: 12px;
  
      color: var(--color-white);
      font-weight: 500;
  
      position: sticky;
      z-index: 1;
    }

    tbody tr:nth-child(even) {
      background-color: rgba(100 41 205 / 19%);
      border: 1px solid var(--color-primary);
      box-shadow: inset 0 0 7px var(--color-primary);
    }
  
    td {
      padding: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      border-right: 1px solid var(--color-primary);
    }
  }
}
