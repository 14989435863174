/**
 * Solicitudes Pragma SA
 */

@import "styles/mixins";

.legalization {
  @include dynamic-height(column, true);
  padding: 16px;
  gap: 18px;

  .subtitle{
    padding-bottom: 6px;
    margin-bottom: 6px;
    font-size: 16px;
    border-bottom: 1px solid var(--color-white);
    display: flex;
    justify-content: space-between;
    color: var(--color-white);
  }

  h3 {
    color: var(--color-white);
    padding-bottom: 4px;
    font-size: 18px;
    font-weight: bold;
  }

  .checkbox {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: center;
  }

  .reminder {
    padding: 16px 22px;
    border-radius: 12px;
    color: var(--color-secondary);
    font-size: 15px;
    background-color: var(--color-shadow-white-primary);
    font-weight: 400;
  }

}

.reject-msg {
  border-radius: 8px;
  border: 2px solid var(--color-error);
  color: var(--color-error);

  h4 {
    padding: 4px 8px;
    background-color: var(--color-error);
    color: var(--color-white);
  }

  p {
    font-size: 12px;
    font-weight: 500;
    color: var(--color-text);
    padding: 8px 12px;
  }
}

.details {
  display: flex;
  flex-direction: column;
  gap: 18px;

  .observations {
    width: 100%;

    textarea {
      min-height: 80px;
    }
  }  

  .resume {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 32px;

    .data {
      display: flex;
      flex-direction: column;
      font-weight: 500;
      width: fit-content;
      color: var(--color-white);

      .value {
        color: var(--color-primary);
        font-size: 24px;
        font-weight: 600;

        &[data-theme="red"] {
          color: var(--color-error);
        }

        &[data-theme="green"] {
          color: var(--color-success);
        }

        .currency {
          font-size: 12px;
          font-weight: 500;
        }
      }
    }
  }

  .separator {
    border-bottom: 1px solid var(--color-comp);
    width: 100%;
  }

  .fields {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;

    >* {
      flex: 1;
    }
  }
}

.payroll {
  display: flex;
  flex-direction: column;
  gap: 12px;
  color: var(--color-white);

  .checkboxes {
    display: flex;
    align-items: center;
    gap: 12px;

    .error {
      color: var(--color-error);
      font-weight: 500;
    }
  }

  p {
    margin: 0;
    font-size: 11px;
    color: var(--color-text);
  }
}

.legalize {
  min-height: 38px;
  align-self: center;
  width: fit-content;
}

.checkbox-error {
  color: var(--color-error);
}
