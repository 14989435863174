/**
 * Solicitudes Pragma SA
 */

.money {
  display: flex;
  flex-direction: column;
  gap: 12px;

  h3 {
    padding-bottom: 4px;
    color: var(--color-white);
    font-size: 18px;
  }

  h4 {
    padding-bottom: 4px;
    color: var(--color-white);
    font-size: 14px;
  }

  form {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;

    > label {
      min-width: 200px;
    }

    .transport,
    .food,
    .others,
    .currency {
      flex: 3;
    }

    .total {
      flex: 1;
    }
  }

  .payroll {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .checkboxes {
      display: flex;
      gap: 12px;
    }
  }

  .cash{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
  }
}
