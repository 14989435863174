/**
 * Solicitudes Pragma SA
 */

.form {
  display: flex;
  flex-direction: column;
  gap: 18px;
  
  .reminder {
    padding: 16px 22px;
    border-radius: 12px;
    color: var(--color-secondary);
    font-size: 15px;
    background-color: var(--color-shadow-white-primary);
    font-weight: 400;
  }

  .action {
    align-self: center;
    margin: 18px 0;
    width: 200px;
  }

  .error {
    background-color: var(--color-error);
    border-radius: 8px;
    padding: 8px 12px;
    font-size: 12px;
    color: var(--color-white);
    font-weight: 500;
  }
}
