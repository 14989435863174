/**
 * Solicitudes Pragma SA
 */

 @import "styles/mixins";

.information {
  @include dynamic-height(column);
  gap: 18px;
  margin-bottom: 24px;
}

