/**
 * Solicitudes Pragma SA
 */

 @import "styles/mixins";

.overview {
  @include dynamic-height(column);
  gap: 18px;
  padding: 0 16px;

  .actions {
    display: flex;
    justify-content: flex-start;
    gap: 12px;
    margin-top: 24px;
  }
}

.filters {
  padding: 10px 40px;
}
