/**
 * Solicitudes Pragma SA
 */

.not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100vw;
  height: 100vh;

  h1 {
    font-size: 120px;
    color: var(--color-primary);
    font-weight: bold;
    margin: 0;
  }

  h2 {
    font-size: 60px;
    color: var(--color-text);
    font-weight: bold;
    margin: 0;
  }

  h3 {
    font-size: 32px;
    color: var(--color-text);
    font-weight: bold;
    margin: 0;
  }

  h4 {
    font-size: 24px;
    color: var(--color-text-lighten);
    font-weight: 400;
  }
}
