/**
 * Solicitudes Pragma SA
 */

.tooltip {
  position: relative;

  &-label {
    border-radius: 10px;
    visibility: hidden;
    width: 120px;
    background-color: var(--color-white);
    opacity: 0.9;
    color: var(--color-days);
    text-align: center;
    font-size: 0.8em;
    padding: 5px 0;
    box-shadow: 0 0 10px var(--color-shadow-white-primary);
    position: absolute;
    z-index: 1;
  }

  &-label::after {
    content: "";
    position: absolute;
    border-width: 5px;
    border-style: solid;
  }

  &:hover &-label {
    visibility: visible;
  }

  // position top
  &.top &-label {
    margin-left: -60px;
    bottom: 150%;
    left: 50%;
  }

  &.top &-label::after {
    margin-left: -5px;
    top: 100%;
    left: 50%;
    border-color: var(--color-white) transparent transparent transparent;
  }

}
