/**
 * Solicitudes Pragma SA
 */

@import "styles/mixins";

.history {
  @include dynamic-height(column);
  gap: 18px;
  padding: 0 16px;
}

.state {
  display: flex;
  flex-direction: column;
  padding-left: 44px;
  row-gap: 4px;
  min-width: 150px;

  font-weight: 600;
  font-size: 14px;
  color: var(--color-white);
}

.details{
  margin-left: auto;
}
