/**
 * Solicitudes Pragma SA
 */

@import "styles/mixins";

.search {
  @include dynamic-height(column);
  gap: 18px;
  padding: 4px;

  form {
    display: flex;
    align-items: flex-end;
    gap: 12px;
  }

  .content {
    @include dynamic-height(column, true);
    gap: 18px;
    border-radius: 8px;
  } 
}
