/**
 * Solicitudes Pragma SA
 */

.tickets {
  display: flex;
  flex-direction: column;
  gap: 18px;

  h3 {
    padding-bottom: 4px;
    color: var(--color-white);
    font-size: 1.2em;
  }
}

.supports {
  display: flex;
  align-items: flex-start;
  gap: 18px;

  ul.file-list {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    list-style: none;
    padding: 8px;
    width: 100%;
    min-height: 80px;

    border-radius: 8px;
    border: 1px solid var(--color-gray);
  
    li {
      display: flex;
      align-items: center;
      background: var(--color-comp);
      border-radius: 14px;
      padding-left: 12px;
      height: fit-content;

      .new {
        font-size: 24px;
        color: var(--color-success);
        margin-right: 4px;
      }
  
      .link {
        overflow: hidden;
        white-space: nowrap;
        min-width: unset;
        text-overflow: ellipsis;
        max-width: 200px;
        min-height: 0;
        font-size: 12px;
      }
      
      .cancel {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 12px;
        padding: 0;
        min-height: 28px;
        width: 28px;
        border-radius: 50%;
      }
    }
  }
}

.comment {
  width: 100%;

  textarea {
    min-height: 80px;
  }
}

.actions {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  border-top: 1px solid var(--color-comp);
  padding-top: 8px;
}
