/**
 * Solicitudes Pragma SA
 */

.details {
  display: flex;
  flex-direction: column;
  gap: 18px;

  h3 {
    color: var(--color-white);
    padding-bottom: 4px;
    font-size: 18px;
    font-weight: 400;
  }

  .resume {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 32px;

    .data {
      display: flex;
      flex-direction: column;
      font-weight: 500;
      width: fit-content;
      color: var(--color-white);

      .value {
        color: var(--color-primary);
        font-size: 24px;
        font-weight: 600;

        &[data-theme="red"] {
          color: var(--color-error);
        }

        &[data-theme="green"] {
          color: var(--color-success);
        }

        .currency {
          font-size: 12px;
          font-weight: 500;
        }
      }
    }
  }

  .separator {
    border-bottom: 1px solid var(--color-comp);
    width: 100%;
  }

  .fields {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;

    > * {
      flex: 1;
    }
  }
}
