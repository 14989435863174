/**
 * Solicitudes Pragma SA
 */

 .header {
  width: 100%;
  display: flex;
  align-items: flex-start;
  z-index: 1;

  .extend-line {
    height: 15px;
    flex-grow: 1;
    background-color: #4f20a4;

    @media (max-width: 800px) {
      display: none;
    }
  }

  .container {
    width: 1246px;
    display: flex;
  }

  .line {
    width: 100%;
    height: 15px;
    position: relative;
    background: rgb(80 32 166);
    background: linear-gradient(180deg, rgba(80 32 166 / 100%) 0%, rgba(79 32 162 / 100%) 100%);

    @media (max-width: 800px) {
      display: none;
    }
  }

  .header-line {
    width: 100%;
    display: flex;
    flex-direction: column;

    .line-left::after {
      content: '';
      height: 20px;
      width: 20px;
      background: rgb(79 32 162);
      background: linear-gradient(180deg, rgba(79 32 162 / 100%) 0%, rgba(77 31 159 / 100%) 100%);
      position: absolute;
      top: 15px;
      right: 0;
    }

    .line-right {
      width: 101%;

      @media (max-width: 1264px) {
        width: 100%;
      }
    }

    .line-right::before {
      content: '';
      height: 20px;
      width: 20px;
      background: rgb(79 32 162);
      background: linear-gradient(180deg, rgba(79 32 162 / 100%) 0%, rgba(77 31 159 / 100%) 100%);
      position: absolute;
      top: 15px;
      left: 0;
      z-index: -10;
    }

    .container-logo {
      display: flex;
      align-items: center;
      gap: 16px;
      margin: 10px 0 0 30px;

      @media (max-width: 800px) {
        justify-content: space-between;
        margin: 10px 30px;
      }

      .menu {
        display: none;
        font-size: 32px;
        color: var(--color-primary);

        @media (max-width: 1220px) {
          display: flex;
        }
      }
    }

    .box-logo {
      background: transparent;
      position: relative;
      border-radius: 100%;
      box-shadow: 0 0 0 1px rgb(0 0 0 / 1%);
      cursor: pointer;
      padding: 1px 7px 0 5px;
      width: 42px;
      height: 42px;
      border: 2px solid white;

      @media (max-width: 1220px) {
        display: none;
      }

      @media (max-width: 800px) {
        display: block;
      }
    }

    .box-logo:hover svg {
      transform: scale(1.02);
      transition: linear 0.4s;
    }

    .box-logo::after {
      opacity: 0;
      transition: opacity 0.5s linear;
      position: absolute;
      content: '';
      top: 5px;
      left: 10px;
      right: 0;
      z-index: -1;
      height: 80%;
      width: 80%;
      transform: scale(0.9) translateZ(0);
      filter: blur(15px);
      border-radius: 100px;
      background: linear-gradient(to left, var(--color-primary), var(--color-pink));
      background-size: 200% 200%;
      animation: animate-glow 2.5s linear infinite;
    }

    .box-logo:hover::after {
      opacity: 1;
    }

    .separate {
      box-shadow: 0 4px 4px rgba(0 0 0 / 25%);
      width: 1px;
      height: 34px;
      background-color: var(--color-white);

      @media (max-width: 1220px) {
        display: none;
      }
    }

    .header-logo {
      font-weight: 700;
      font-size: 12px;
      color: var(--color-white);

      @media (max-width: 1220px) {
        display: none;
      }
    }

    .container-user {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 16px;
      margin: 10px 30px 0 0;
    }
  }

  .header-line:nth-child(3) {
    @media (max-width: 800px) {
      display: none;
    }
  }

  .links {
    background: rgba(100 41 205 / 10%);
    box-shadow: inset 0 0 80px var(--color-primary);
    border-bottom: 1px solid var(--color-primary);
    display: flex;
    gap: 45px;
    padding: 10px 30px;
    border-radius: 0 0 20px 20px;
    position: relative;
    height: 72px;
    align-items: center;

    @media (max-width: 800px) {
      display: none;
    }

    .link {
      display: flex;
      text-decoration: none;
      margin-top: 26px;
      padding: 0;
      flex-direction: column;

      .container-title {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        text-decoration: none;
        margin: 0;
        padding: 0;
      }

      h2.title {
        color: var(--color-white);
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        position: relative;
        white-space: nowrap;
      }

      &:hover h2.title {
        font-weight: 700;
      }

      .notifications {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 1px;

        background-color: rgba(255 255 255 / 10%);
        border-radius: 12px;
        box-shadow: inset 0 0 6px rgba(255 255 255 / 20%);

        border: 1px solid var(--color-white);
        height: 24px;
        min-width: 24px;
        margin-left: 10px;

        font-size: 10px;
        font-weight: 600;
        color: var(--color-white);
      }
    }

    .active {
      cursor: default;

      h2.title-custom {
        cursor: default;
        font-weight: 700;
      }
    }

    .active-custom {
      h2.title-custom {
        font-weight: 400;
      }
    }
  }

  .lighting {
    width: 100%;
    border-bottom: 3px solid var(--color-white);
    box-shadow: 0 -3px 11px var(--color-shadow-white-primary);
    border-radius: 120px;
    margin-top: 17px;
  }

  .lighting-off {
    width: 100%;
    border-bottom: 3px solid transparent;
    border-radius: 120px;
    margin-top: 17px;
  }

  .links::after {
    content: '';
    height: 40px;
    width: 40px;
    background: var(--color-background);
    position: absolute;
    top: 15px;
    left: -40px;
    border-radius: 50%;
  }

  .links::before {
    content: '';
    height: 40px;
    width: 40px;
    background: var(--color-background);
    position: absolute;
    top: 15px;
    right: -40px;
    border-radius: 50%;
  }

  .translation {
    @media (max-width: 900px) {
      display: none;
    }
  }
}

@keyframes animate-glow {
  0% {
    background-position: 0% 50%;
  }

  100% {
    background-position: 200% 50%;
  }
}
