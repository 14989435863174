/**
 * Solicitudes Pragma SA
 */

.filter {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 12px;
  margin: 16px 0;

  > * {
    flex: 2;
  }

  .filter-id {
    min-width: 235px;
    margin-bottom: 12px;
  }

  .search {
    flex: 1;
    margin-top: 17px;
    min-width: 80px;
  }
}
