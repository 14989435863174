/**
 * Solicitudes Pragma SA
 */

.modal {
  position: fixed;
  z-index: 2;

  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  background: rgba(100 41 205 / 20%);
  backdrop-filter: blur(10px);

  .container {
    min-width: 400px;
    border: 1px solid var(--color-primary);
    border-radius: 27px;
    background-color: var(--color-background);
    box-shadow: 0 8px 60px rgba(100 41 205 / 80%), inset 0 0 30px rgba(100 41 205 / 50%);
  }

  .header {
    display: flex;
    align-items: center;
    background: var(--color-primary);
  
    padding: 12px 18px;
    border-radius: 25px 25px 0 0;
  
    h2 {
      margin: 0;
      color: var(--color-white);
      font-size: 16px;
      font-weight: 700;
    }
  
    .close {
      margin-left: auto;
      color: var(--color-white);
      font-size: 18px;

      cursor: pointer;
    }
  }
  
  .body {
    padding: 18px;
    color: var(--color-white);
  }
  
  .footer {
    display: flex;
    justify-content: center;
    padding: 18px;
  
    .button {
      width: 150px;
    }
  }
}
