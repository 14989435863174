/**
 * Solicitudes Pragma SA
 */

.select {
  display: flex;
  flex-direction: column;
  width: 100%;
}

// Label
.select[data-label] {
  font-weight: 700;
  color: var(--color-white);
  margin-bottom: 12px;
}

// Disabled
.select[data-disabled] {
  opacity: 0.7;
  cursor: default;
}

.helper {
  align-self: flex-end;
  margin-top: 3px;
  font-size: 0.9em;
  opacity: 0.7;
}

// Error
.select[data-error] {
  .helper {
    color: var(--color-error);
  }
}
