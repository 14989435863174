/**
 * Solicitudes Pragma SA
 */

 .card-size {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid var(--color-primary);
    border-radius: 25px;
    box-shadow: inset 0 0 30px rgba(100 41 205 / 50%);
    backdrop-filter: blur(50px);
    color: var(--color-white);
    max-width: 260px;
    cursor: pointer;

    &:hover {
        box-shadow: 0 0 20px rgba(100 41 205 / 50%), inset 0 0 30px rgba(100 41 205 / 50%);
    }

    .header-size {
        border-bottom: 1px solid var(--color-primary);
        background: rgba(100 41 205 / 30%);
        border-radius: 25px 25px 0 0;
        display: flex;
        padding: 8px 16px;
        align-items: center;
        gap: 12px;
        font-weight: 700;
        width: 100%;
        
        .container-size {
            background: var(--color-primary);
            box-shadow: 0 0 8px rgb(100 41 205);
            border-radius: 50%;
            opacity: 0.6;
            width: 32px;
            height: 32px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 16px;
        }
    }

    .children {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px 26px;
        min-height: 200px;
        justify-content: space-between;
    }
 }

 .card-size[data-theme='selected'] {
    border: 1px solid rgba(189 189 255 / 40%);
    box-shadow: inset 0 0 30px rgba(189 189 255 / 30%);

    .header-size[data-theme='selected'] {
        border-bottom: 1px solid rgba(189 189 255 / 40%);
        background: rgba(100 41 205 / 60%);

        .container-size[data-theme='selected'] {
            background: var(--color-white);
            box-shadow: 0 0 8px rgba(189 189 255 / 80%);
            color: #409;
            opacity: 1;
        }
    }

    .children[data-theme='selected'] {
        background: rgba(100 41 205 / 60%);
        border-radius: 0 0 25px 25px;
        width: 100%;
    }
 }

 .disabled {
    cursor: not-allowed;

    &:hover {
        box-shadow: inset 0 0 30px rgba(100 41 205 / 50%);
    }
 }
