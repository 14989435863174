/**
 * Solicitudes Pragma SA
 */

.title {
  color: var(--color-white);
  padding-bottom: 4px;
  font-size: 18px;
  width: 100%;
}
