/**
 * Solicitudes Pragma SA
 */

@import "styles/mixins";

.cost-center {
  display: flex;
  flex-direction: column;
  gap: 12px;

  h3 {
    padding-bottom: 4px;
    color: var(--color-white);
    font-size: 18px;
    font-weight: 400;
  }

  .description {
    width: 100%;

    textarea {
      min-height: 80px;
    }
  }

  form {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
  }

  .column {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
  }

  .error {
    background-color: var(--color-error);
    border-radius: 8px;
    padding: 8px 12px;
    font-size: 12px;
    color: var(--color-white);
    font-weight: 500;
  }
}

.reminder-actions {
  display: flex;
  margin-top: 10px;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 26px;
}

.actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.validation {
  color: var(--color-white);
  text-decoration: underline;
}

