/**
 * Solicitudes Pragma SA
 */

@import "styles/mixins";

.vacations-request {
  @include dynamic-height(column, true);
  padding: 16px;

  form {
    display: flex;
    gap: 32px;
    padding: 18px 4px;
  
    .separator {
      border-left: 2px solid var(--color-white);
    }
  
    @media (max-width: 800px) {
      flex-wrap: wrap;
      justify-content: center;
  
      .separator {
        display: none;
      }
    }
  
    h2 {
      color: var(--color-white);
      font-size: 18px;
      margin-bottom: 16px;
      font-weight: 400;
    }
  
    .form {
      display: flex;
      flex-direction: column;
      gap: 12px;
      width: auto;
  
      @media (max-width: 800px) {
        width: 100%;
      }
    }
  
    .calendar {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 12px;
      max-width: 250px;
  
      h2 {
        text-align: center;
      }
    }
  
    .send {
      align-self: flex-start;
    }
  }

  .container-available-days{
    display: flex;
    align-items: center;
    gap: 18px;
  }

  .days-title {
    font-size: 16px;
    font-weight: 700;
    color: var(--color-white);
  }

  .container-days{
    background-color: var(--color-shadow-white-primary);
    border-radius: 50%;
    min-width: 64px;
    height: 64px;
    align-items: center;
    justify-content: center;
    display: flex;
  }

  .days-data {
    font-size: 21px;
    font-weight: 600;
  }
  
  .resume {
    color: var(--color-white);
    font-weight: 500;
    text-align: center;

    strong {
      color: var(--color-pink);
    }
  }  
}
