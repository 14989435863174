/**
 * Solicitudes Pragma SA
 */

.refresh {
  display: flex;
  justify-content: flex-start;

  .button {
    margin: 18px 0;
  }
}

.state {
  display: flex;
  flex-direction: column;
  padding-left: 44px;
  row-gap: 4px;
  min-width: 150px;

  font-weight: 600;
  font-size: 14px;
  color: var(--color-white);
}

.flag {
  margin: 30px 0 0 12px;
}

.modal {
  &-content {
    display: flex;
    flex-direction: column;
    width: 500px;
  }

  &-header {
    display: flex;
    justify-content: space-between;
  }

  &-creationDate {
    font-weight: bold;
    line-height: 1.2em;
    margin-left: 10px;
  }

  &-list {
    margin-top: 10px;
    padding-left: 5px;

    li {
      color: var(--color-white);
      display: inline-block;
      margin-right: 15px;
    }

    li::before {
      content: "• ";
    }
  }

  &-description {
    margin-top: 20px;
    width: 100%;

    textarea {
      min-height: 80px;
    }
  }
}

.details{
  margin-left: auto;
}
