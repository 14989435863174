/**
 * Solicitudes Pragma SA
 */

.search{
  display: flex;
  justify-content: flex-start;

  form {
    display: flex;
    align-items: center;
    gap: 12px;
    margin: 18px 0;
    width: 330px;
  }
}

.state-info {
  display: flex;
  flex-direction: column;
  padding-left: 44px;
  row-gap: 4px;
  min-width: 150px;

  font-weight: 600;
  font-size: 14px;
  color: var(--color-white);
}

.details{
  margin-left: auto;
}
