.screen {
  position: absolute;
  z-index: 50;

  top: 0;
  left: 0;
  height: auto;
  width: 100%;

  display: flex;
  justify-content: center;

  .toast {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 80px;
    height: auto;
    padding: 12px 18px;

    border-radius: 8px;

    color: var(--color-white);

    &[data-color='danger'] {
      background: var(--color-error);
      box-shadow: 0 8px 30px var(--color-shadow-error-primary),
        inset 0 0 30px var(--color-shadow-error-secondary);
    }

    &[data-color='success'] {
      background: var(--color-success);
      box-shadow: 0 8px 30px var(--color-shadow-success-primary),
        inset 0 0 30px var(--color-shadow-success-secondary);
    }

    &[data-color='warning'] {
      background: var(--color-warning);
      box-shadow: 0 8px 30px var(--color-shadow-warning-primary),
        inset 0 0 30px var(--color-shadow-warning-secondary);
    }

    &[data-color='information'] {
      background: var(--color-information);
      box-shadow: 0 8px 30px var(--color-shadow-information-primary),
        inset 0 0 30px var(--color-shadow-information-secondary);
    }

    .icon {
      color: white;
      margin-right: 5px;
    }
    
  }
}
